<template>
  <div class="container carAllocation">
    <el-card class="card-left">
      <div class="card-left-tree">
        <el-input
          placeholder="输入关键字进行过滤"
          size="small"
          style="width: 500px"
          v-model="filterText"
        />
        <el-scrollbar>
          <el-tree
            ref="tree"
            :data="orgTree"
            :props="defaultProps"
            node-key="id"
            highlight-current
            @node-click="treeNodeClick"
            :expand-on-click-node="false"
            :default-expanded-keys="defaultExpanded"
            :filter-node-method="filterNode"
          ></el-tree>
        </el-scrollbar>
      </div>
      <div class="card-left-car">
        <el-input
          placeholder="输入关键字进行过滤"
          size="small"
          v-model="filterCar"
        />
        <el-scrollbar>
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-checkbox-group
            class="group"
            v-model="checkCars"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox
              v-for="item in groupList"
              :label="item.id"
              :key="item.id"
              >{{ item.carPlateNum }}</el-checkbox
            >
          </el-checkbox-group>
          <!-- <el-radio-group class="group" v-model="formData.id" @change="radioGroupChange">
            <el-radio v-for="item in groupList" :label="item.id" :key="item.id">
              {{ item.carPlateNum }}
            </el-radio>
          </el-radio-group> -->
        </el-scrollbar>
      </div>
    </el-card>
    <div class="card-center">
      <el-button
        :disabled="!isAuth('obd:car:move')"
        type="primary"
        size="small"
        @click="submit"
      >
        <i class="el-icon-arrow-right"></i>
      </el-button>
    </div>
    <el-card class="card-right">
      <div class="card-right-tree">
        <el-input
          placeholder="输入关键字进行过滤"
          size="small"
          v-model="rightFilterText"
        />
        <el-scrollbar>
          <el-tree
            ref="rightTree"
            :data="rightTree"
            :props="defaultProps"
            node-key="id"
            highlight-current
            @node-click="selectOrgId"
            :expand-on-click-node="false"
            :default-expanded-keys="rightDefaultExpanded"
            :filter-node-method="filterNode"
          ></el-tree>
        </el-scrollbar>
      </div>
    </el-card>
  </div>
</template>

<script>
import { treeDataTranslate } from "@/utils";
export default {
  name: "carAllocation",
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
      orgTree: [],
      rightTree: [],
      tableData: [],
      curNode: {},
      filterText: "", // 左边过滤树input
      rightFilterText: "", // 右边过滤树input
      checkCars: [],
      checkAll: false,
      isIndeterminate: true,
      filterCar: "", // 过滤车group
      formData: {
        id: "",
        curCar: {},
        node: {},
      },
      pageNo: 1,
      pageSize: 99999,
      times: null,
    };
  },
  computed: {
    defaultExpanded() {
      let _B = Boolean(this.orgTree && this.orgTree.length);
      let arr = _B ? [this.orgTree[0].id] : [];
      return arr;
    },
    rightDefaultExpanded() {
      let _B = Boolean(this.rightTree && this.rightTree.length);
      let arr = _B ? [this.rightTree[0].id] : [];
      return arr;
    },
    groupList() {
      let _tableData = this.tableData,
        _filterCar = this.filterCar;
      return _filterCar != ""
        ? _tableData.filter(
            (item) => item.carPlateNum.indexOf(_filterCar) != -1
          )
        : _tableData;
    },
  },
  watch: {
    filterText(val) {
      if (this.times) {
        clearTimeout(this.times);
      }
      this.times = setTimeout(() => {
        this.$refs.tree.filter(val);
      }, 500);
    },
    rightFilterText(val) {
      if (this.times) {
        clearTimeout(this.times);
      }
      this.times = setTimeout(() => {
        this.$refs.rightTree.filter(val);
      }, 500);
    },
  },
  mounted() {
    this.getOrgList();
    this.getRightOrgList();
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.nodeName.indexOf(value) !== -1;
    },
    getOrgList() {
      this.$http({
        url: this.$http.adornUrl("/sys/orgArch/list"),
        methods: "get",
      })
        .then((res) => {
          this.orgTree = treeDataTranslate(res.data.data, "id", "parendId");
          this.curNode = JSON.parse(JSON.stringify(this.orgTree[0]));
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    getRightOrgList() {
      this.$http({
        url: this.$http.adornUrl("/sys/orgarch/alllist"),
        methods: "get",
      })
        .then((res) => {
          this.rightTree = treeDataTranslate(res.data.data, "id", "parendId");
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    treeNodeClick(node) {
      // if (!node.children) {
        this.reset();
        this.curNode = JSON.parse(JSON.stringify(node));
        this.getDevList();
      // } else {
      //   if (node.nodeType != 3)
      //     return this.$message.warning("请选择下级节点进行车辆调拨!");
      //   this.reset();
      //   this.curNode = JSON.parse(JSON.stringify(node));
      //   this.getDevList();
      // }
    },
    selectOrgId(node) {
      if (!node.children) {
        this.formData.node = node;
      } else {
        if (node.nodeType != 3)
          return this.$message.warning("请选择下级节点进行车辆调拨!");
        this.formData.node = node;
      }
    },
    getDevList() {
      if (this.curNode.id == "") return this.$message.warning("请选择单位!");
      const Loading = this.$mask();
      this.$http({
        url: this.$http.adornUrl("/obd/car/move/list"),
        method: "get",
        params: {
          // pageNo: this.pageNo,
          // pageSize: this.pageSize,
          // orgId: this.curNode.id,
          // carPlateNum: "",
          // carPlateColor: "",
          // id: "",
          orgId: this.curNode.id,
        },
      })
        .then((res) => {
          Loading.close();
          // this.tableData = this.tableData.concat(res.data.page.list || []);
          // this.tableData = res.data.page.list;
          this.tableData = res.data.data;
        })
        .catch((err) => {
          Loading.close();
          this.$message.error(err.msg);
        });
    },
    handleCheckAllChange(val) {
      this.checkCars = val ? this.groupList.map((el) => el.id) : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      this.checkCars = value;
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.groupList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.groupList.length;
    },
    radioGroupChange(val) {
      this.formData.curCar = this.groupList.find((el) => el.id == val);
    },
    submit() {
      // if (!this.formData.id) return this.$message.warning("请选择车辆!");
      if (!this.checkCars.length) return this.$message.warning("请选择车辆!");
      if (!this.formData.node.id)
        return this.$message.warning("请选择右侧节点!");
      // let warn = `是否将【${this.curNode.nodeName}】的【${this.formData.curCar.carPlateNum}】调拨到【${this.formData.node.nodeName}】`;
      let warn = `所选车辆确定调拨到【${this.formData.node.nodeName}】`;
      this.$confirm(warn, "请确认")
        .then(() => {
          return this.$http({
            url: this.$http.adornUrl("/obd/car/move"),
            method: "post",
            data: this.$http.adornData({
              // id: this.formData.id,
              id: this.checkCars.join(),
              orgId: this.formData.node.id,
            }),
          });
        })
        .then((res) => {
          this.$message.success(res.data.msg);
          this.getDevList();
          this.reset();
        })
        .catch((err) => {
          if (err && err.msg) this.$message.error(err.msg);
        });
    },
    reset() {
      this.tableData = [];
      // this.formData.id = "";
      // this.formData.curCar = {};
      this.pageNo = 1;

      // 全选
      this.checkCars = [];
      this.isIndeterminate = true;
    },
  },
};
</script>

<style lang="less" scoped>
.carAllocation {
  display: flex;
  height: 600px;
  .card-left,
  .card-center,
  .card-right {
    height: 100%;

    ::v-deep .el-card__body {
      // slot="header" 高度为 58px
      height: 100%;
      display: flex;
    }
  }

  .card-left {
    width: 800px;
    &-tree {
      width: 500px;
    }
    &-car {
      width: 200px;
      padding: 0 0 0 20px;
      overflow: auto;
    }
    &-tree,
    &-car {
      height: 100%;
      ::v-deep .el-scrollbar {
        height: calc(100% - 40px);
      }

      ::v-deep .el-scrollbar__wrap {
        height: calc(100% + 17px);
      }
    }
  }
  .card-center {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-right {
    width: 500px;
    &-tree {
      width: 100%;
      height: 100%;
      ::v-deep .el-scrollbar {
        height: calc(100% - 40px);
      }

      ::v-deep .el-scrollbar__wrap {
        height: calc(100% + 17px);
      }
    }
  }
  .group {
    ::v-deep .el-radio {
      display: block;
      margin: 10px 0 0 4px;
    }
    ::v-deep .el-checkbox {
      display: block;
      margin: 10px 0 0 4px;
    }
  }
}
</style>
